import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  sideBarItem = new BehaviorSubject<string>('');
  sideBarItemForPolicyPack = new BehaviorSubject<string>('');
  getConversationHistory = new BehaviorSubject<string>('');
  removeAdminFromSideBar = new BehaviorSubject<string>('');
  getConversationIdForDetail = new BehaviorSubject<string>('');
  refreshProfile = new BehaviorSubject<boolean>(false)
  constructor(private http: HttpClient) { }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('lsEnterprise');
    if (encodedData) return JSON.parse(atob(encodedData));
  }

  //Setting ======>> User Profile API's End
  // GetUserProfile(body): Observable<any> {
  //   return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
  // }
  SetUserProfile(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/SetUserProfile', body);
  }
  ChangePassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body);
  }
  forgotPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body)
  }
  resetPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body);
  }

  //Get Dashboard Data
  GetDashboard(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Dashboard/GetDashboardData', body);
  }

  //Jobs List
  GetJobsList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Job/GetJobs', body);
  }

  GetProductsList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Product/GetAllProducts', body);
  }

  GetContacts(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Contacts/GetContacts', body);
  }

  SaveJob(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Job/SaveJob', body);
  }

  //Admin ====>> User ====>> getUserList API
  vault(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body)
  }

  newUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body)
  }

  editUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body)
  }

  resetUserPassword(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body)
  }

  toggleSuspendUser(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body)
  }

  deleteUser(id: any): Observable<any> {
    return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser' + "?id=" + id);
  }


  getProducts(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/page/DateSpec', body)
  }

  //Get All ATMs
  getATMList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ATM/GetAllATMs', body)
  }

  //Add ATM
  addATM(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ATM/AddATM', body)
  }

  //Edit ATM
  editATM(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ATM/EditATM', body)
  }

  //Get All Reps
  getReps(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Rep/GetAllReps', body)
  }

  //Add Rep
  addRep(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Rep/AddRep', body)
  }

  //Edit Rep
  editRep(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Rep/EditRep', body)
  }

  //Get All Consignees
  getAllConsignees(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Consignee/GetAllConsignees', body)
  }

  //Add Consignee
  addConsignees(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Consignee/AddConsignee', body)
  }

  //edit Consignee
  editConsignees(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Consignee/EditConsignee', body)
  }

  //Get All Customers
  getCustomers(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Customer/GetAllCustomers', body)
  }

  //Add Customer
  addCustomer(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Customer/SaveCustomer', body)
  }

  //Get All Distributors
  getDistributorsList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Distributor/GetAllDistributors', body)
  }

  //Add Distributor
  addDistributor(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Distributor/AddDistributor', body)
  }

  //Edit Distributor
  editDistributor(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Distributor/EditDistributor', body)
  }

  getBfsReports(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Report/GetBFSReport', body)
  }
  getHReports(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Report/GetHReport', body)
  }
  getStates(): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/States/GetAllStates', {})
  }


  getProductsList(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateSpec/GetDateSpec', body)
  }

  copyDateSpec(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateSpec/CopyDateSpec', body)
  }

  updateDateSpec(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateSpec/UpdateDateSpec', body)
  }

  deleteDateSpec(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateSpec/DeleteDateSpec', body)
  }

  // Get Customer Details ObjectGraph
  customerDetails(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Customer/ObjectGraph', body)
  }

  // Add Customer Contact
  AddContact(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Contacts/AddContact', body)
  }

  // Edit Customer Detail
  EditCustomer(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Customer/EditCustomer', body)
  }

  // Edit Customer Contact
  EditContact(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Contacts/EditContact', body)
  }

  // Edit Customer Contact
  SaveArtwork(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Artwork/SaveArtwork', body)
  }

  // Upload Image
  UploadImage(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/saveImage', body)
  }

  // Get Job Details ObjectGraph
  jobDetail(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Job/ObjectGraph', body)
  }

// Get DateProducts
GetDateProducts(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProduct/GetDateProducts', body)
}
  


  // Get Job GetOptions
  GetOptions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Options/GetOptions', body)
  }

  // Get Job GetCustomOptions
  GetCustomOptions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Options/GetCustomOptions', body)
  }

  // Options ---->>  SaveOptions
  SaveOptions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Options/SaveOptions', body)
  }

  // Address --->> SaveAddress
  SaveAddress(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Address/SaveAddress', body)
  }

  // Address --->> DeleteAddress
  DeleteAddress(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Address/DeleteAddress', body)
  }

  // Note --->> CreateNote
  CreateNote(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Note/CreateNote', body)
  }

  // Note --->> UpdateNote
  UpdateNote(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Note/UpdateNote', body)
  } 
  
  // DateSpec --->> GetDateSpecById
  GetDateSpecById(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateSpec/GetDateSpecById', body)
  } 
  
  // DateProduct --->> GetDateProductsById
  GetDateProductsById(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProduct/GetDateProductsById', body)
  } 

  // ProductOption --->> GetProductOptions
  GetProductOptions(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ProductOption/GetProductOptions', body)
  } 

 // DateProductOption --->> GetDateProductOptions
 GetDateProductOptions(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProductOption/GetDateProductOptions', body)
} 

 // Product --->> SaveProduct
 SaveProduct(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Product/SaveProduct', body)
} 

 // Product --->> GetProductCategories
 GetProductCategories(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Product/GetProductCategories', body)
} 

 // ProductOption --->> SaveProductOption
 SaveProductOption(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ProductOption/SaveProductOption', body)
} 

 // DateProductOption --->> SaveDateProductOption
 SaveDateProductOption(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProductOption/SaveDateProductOption', body)
} 

 // Product --->> OptionProducts
OptionProducts(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Product/OptionProducts', body)
}

SaveFileObject(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/folder/saveFileObject', body)
}

 // Attachment --->> Delete Attachment
deleteAttachment(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/file/delete2', body)
}

 // Artwork --->> Delete DeleteArtwork
 DeleteArtwork(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Artwork/DeleteArtwork', body)
}

 // DateProduct --->> Delete DateProduct
 DeleteDateProduct(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProduct/DeleteDateProduct', body)
}

 // DateProductOption --->> Delete DateProductOption
 DeleteDateProductOption(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProductOption/DeleteDateProductOption', body)
}

 // DateProduct --->> Delete Category
 DeleteCategory(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DateProduct/DeleteCategory', body)
}


SaveSessionDetails(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Session/saveSessionDetails', body)
}

GetSessionDetails(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Session/getSessionDetails', body)
}

SetSessionTime(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Session/setSessionTime', body)
}

GetSessionTime(body): Observable<any> {
  return this.http.post<any>(environment.BaseURL + '/ds/function/shared/Session/getSessionTime', body)
}
    
}
