// src/app/guards/role.guard.ts
import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';

const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);
  
  const userRole = sessionService.SessionService_GetUserDetails().role; // Get the user role
  const allowedRoles = route.data['roles'] as Array<string>; // Get allowed roles from route data

  // Check if user role is in allowed roles
  if (allowedRoles && allowedRoles.includes(userRole)) {
    return true; // Access granted
  } else {
    // Redirect to a default route if access is denied
    router.navigate(['/auth/login']);
    return false; // Access denied
  }
};

export const RoleGuard = canActivate;
