import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionExpiryTimeSubject = new BehaviorSubject<string>('');
  sessionExpiryTime$ = this.sessionExpiryTimeSubject.asObservable();

  constructor() { }

  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }

  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('lsEnterprise', btoa(JSON.stringify(oData)));
  }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('lsEnterprise');
    if (encodedData) return JSON.parse(atob(encodedData));
  }

  //set session expiry
  cSessionService_setSessionExpiry(sessionTime) {
    const nSessionExpiryTime: number = new Date().getTime() + sessionTime;
    localStorage.setItem("exp-ls-" + "SessionExpiryTime", JSON.stringify(nSessionExpiryTime));
  }

  //get session expiry
  cSessionService_getSessionExpiry() {
    return JSON.parse(localStorage.getItem("exp-ls-" + "SessionExpiryTime"));
  }
}
