<div class="modal-header rounded-0">
    <h4 class="modal-title fw-light fs-5" id="modal-basic-title">New Job <i class="fa-solid fa-chevron-right fs-6 mx-1"></i> 
        <span *ngIf="step == 0">Customer </span> 
        <span *ngIf="step == 1">Contacts </span>
        <span *ngIf="step == 2">Product </span>
        <span *ngIf="step == 3">Coordinator & Equipment Rep </span>

    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body custom-height">
<form [formGroup]="newJobForm">
    <!-- First div -->
<div class="mb-3" *ngIf="step === 0">
    <div class="d-flex align-items-center gap-2 mb-3">
        <input type="checkbox" id="customer" class="form-check cursor-pointer" (click)="toggleCustomerSection()" [checked]="isNewCustomer">
        <label class="mb-0 cursor-pointer" for="customer"> Is New Customer? </label>
    </div>

    <div class="form-group" *ngIf="!isNewCustomer">
        <label for="" class="form-label">Select Customer</label>
        <!-- <input type="text" class="form-control"> -->
        <ng-select 
        [editableSearchTerm]="true"
        bindLabel="name"
        bindValue="id"
        class="customize-size"
        [items]="customerCopyList"
        formControlName="customerId"
        (keyup)="customerFilterList($event)"
        (change)="getContacts()"
        (close)="onDropdownClose('customer')"
        >
    </ng-select>
    <small class="text-danger font-12" *ngIf="!newJobForm.get('customerId').valid && newJobForm.get('customerId').touched">
        Required
    </small>
    </div>

    <div *ngIf="isNewCustomer">
        <div class="form-group mb-3">
            <label for="" class="form-label">Customer</label>
            <input type="text" class="form-control" placeholder="Customer" formControlName="customerName">
            <small class="text-danger font-12" *ngIf="!newJobForm.get('customerName').valid && newJobForm.get('customerName').touched">
                Required
            </small>
        </div>
        <div class="row">
            <div class="col-lg-6 mb-3">
                <div class="form-group ">
                    <label for="" class="form-label">City</label>
                    <input type="text" class="form-control" placeholder="City" formControlName="city">
                    <small class="text-danger font-12" *ngIf="!newJobForm.get('city').valid && newJobForm.get('city').touched">
                        Required
                    </small>
                </div>
            </div>
            <div class="col-lg-6 mb-3">
                <div class="form-group ">
                    <label for="" class="form-label">State</label>
                    <ng-select 
                    [editableSearchTerm]="true"
                    [items]="states" 
                    formControlName="state"
                    [items]="states" 
                    bindLabel="stateName" 
                    bindValue="stateShortForm"
                    (keyup)="searchStateReset($event)"
                    >
                      </ng-select>
                      <small class="text-danger font-12" *ngIf="!newJobForm.get('state').valid && newJobForm.get('state').touched">
                        Required
                    </small>
                    
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Step 1 div -->
<div class="mb-3" *ngIf="step === 1">
    <div class="d-flex align-items-center gap-2 mb-4">
        <input type="checkbox" id="contact" class="form-check cursor-pointer" (click)="toggleContactSection()" [checked]="contactSection"> 
        <label class="mb-0 cursor-pointer" for="contact"> Is New Contact? </label>
    </div>

    <div class="form-group" *ngIf="!contactSection">
        <label for="" class="form-label">Select Contact Name</label>
        <ng-select 
        [editableSearchTerm]="true"
        bindLabel="name"
        bindValue="id"
        class="customize-size"
        [items]="contactList"
        formControlName="contactId"
        (keyup)="contactReset($event, '1')"
        >
    </ng-select>
    </div>

    <div *ngIf="contactSection">
        <div class="form-group mb-3">
            <label for="" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" formControlName="contactFName">
        </div>

        <div class="form-group mb-3">
            <label for="" class="form-label">Contact Last Name</label>
            <input type="text" class="form-control" formControlName="contactLName">
        </div>
    </div>

    <hr>

    <div class="d-flex align-items-center gap-2 mb-4">
        <input type="checkbox" id="additional" class="form-check cursor-pointer" (click)="toggleAdditionalSection()" [checked]="additionalSection"> 
        <label class="mb-0 cursor-pointer" for="additional"> Is New Additional Contact? </label>
    </div>

    <div class="form-group mb-3" *ngIf="!additionalSection">
        <label for="" class="form-label">Select Additional Contact Name</label>
        <ng-select 
        [editableSearchTerm]="true"
        bindLabel="name"
        bindValue="id"
        class="customize-size"
        [items]="contactList"
        formControlName="addlContactId"
        (keyup)="contactReset($event, '2')"
        >
    </ng-select>
    </div>

    <div *ngIf="additionalSection">
        <div class="form-group mb-3">
            <label for="" class="form-label">Additional Contact First Name</label>
            <input type="text" class="form-control" formControlName="addContactFName">
        </div>

        <div class="form-group">
            <label for="" class="form-label">Additional Contact Last Name</label>
            <input type="text" class="form-control" formControlName="addContactLName">
        </div>
    </div>
</div>

<!-- Step 2 div -->
<div class="mb-3" *ngIf="step === 2">
    <div class="mb-3">
        <label for="">Product Model</label>
        <ng-select
        [editableSearchTerm]="true"
        class="customize-size" 
        [items]="productsCopyList"
        bindLabel="model"
        bindValue="id"
        formControlName="productId"
        (keyup)="productFilterList($event)"
        (close)="onDropdownClose('product')"
        >
        </ng-select>
        <small class="text-danger font-12" *ngIf="!newJobForm.get('productId').valid && newJobForm.get('productId').touched">
            Required
        </small>
    </div>

    <div class="mb-3">
        <label for="">ATM Model</label>
        <ng-select
        [editableSearchTerm]="true"
        class="customize-size" 
        [items]="atmCopyList"
        formControlName="atmId"
        bindLabel="mfrAndModel"
        bindValue="id"
        (keyup)="atmFilterList($event)"
        (close)="onDropdownClose('atm')"
        >
        </ng-select>
        <small class="text-danger font-12" *ngIf="!newJobForm.get('atmId').valid && newJobForm.get('atmId').touched">
            Required
        </small>
    </div>

    <div class="d-flex align-items-center gap-1 mb-3">
        <input type="checkbox" id="job" class="form-check" [checked]="newJobForm.get('isMJob').value" formControlName="isMJob">
        <label for="job">Is MJob?</label>
    </div>
</div>

  <!-- Step 3 div -->
  <div class="mb-3" *ngIf="step === 3">
    <div class="mb-3">
        <label for="">Coordinator</label>
        <ng-select
        [editableSearchTerm]="true"
        class="customize-size" 
        [items]="userCopyList"
        bindLabel="name"
        bindValue="id"
        (keyup)="userFilterList($event, '1')" 
        formControlName="coordinatorId"
        (close)="onDropdownClose('coordinator')"
        >
        </ng-select>
    </div>

    <div class="mb-3">
        <label for="">Project Manager</label>
        <ng-select
        [editableSearchTerm]="true"
        class="customize-size" 
        [items]="userCopyList"
        bindLabel="name"
        bindValue="id"
        (keyup)="userFilterList($event, '2')" 
        formControlName="projManagerId"
        (close)="onDropdownClose('coordinator')"
        >
        </ng-select>
    </div>

    <div class="d-flex align-items-center gap-1 mb-3">
        <input type="checkbox" id="equipment" class="form-check" (click)="toggleEquiptmentRep()" [checked]="equipRep">
        <label for="equipment" class="cursor-pointer">Is New Equipment Rep?</label>
    </div>

    <div class="mb-3" *ngIf="!equipRep">
        <label for="">Equipment Rep</label>
        <ng-select
        [editableSearchTerm]="true"
        class="customize-size" 
        [items]="repsCopyList"
        formControlName="repId"
        bindLabel="name"
        bindValue="id"
        (keyup)="repFilterList($event)"   
        (close)="onDropdownClose('rep')"                                         
        >
        </ng-select> 
    </div>

    <div *ngIf="equipRep">
        <div class="form-group">
            <label for="" class="form-label">Equipment Rep First Name</label>
            <input type="text" class="form-control" formControlName="repFName">
        </div>

        <div class="form-group">
            <label for="" class="form-label">Equipment Rep Last Name</label>
            <input type="text" class="form-control" formControlName="repLName">
        </div>
    </div>

</div>

</form>
</div>

<!-- Footer -->
<div class="modal-footer border-0 d-flex align-items-center justify-content-center gap-1">
    <button class="btn btn-info" *ngIf="step > 0" (click)="previousPage()"><i class="fa-solid fa-chevron-left ms-1"></i> Previous</button>
    <button class="btn btn-info" *ngIf="step < 3" (click)="nextPage()">Next <i class="fa-solid fa-chevron-right ms-1"></i></button>
    <button class="btn btn-info" *ngIf="step == 3" (click)="saveJob()">Save</button>
    <button class="btn btn-danger" [ngbPopover]="popContent" [popoverTitle]="popTitle">Cancel</button>
</div>


<ng-template #popContent>
    <p>Are you sure you want to dismiss <br> the form? </p>
    <hr>
    <div class="d-flex align-items-center gap-1">
        <button class="btn btn-sm btn-primary"(click)="dismiss()" >Yes</button>
        <button class="btn btn-sm btn-danger">No</button>
    </div>
 </ng-template>
 <ng-template #popTitle>
     <h5 class="mb-0 text-secondary">Confirm</h5>
 </ng-template>