import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';


interface JobsTickets {
  id:number,
  jobId: number;
  status1: string;
  coordinatorId: number;
  purchaseOrder: string;
  coordinatorName: string;
  customer: {
      name: string,
      id:number,
      city:string,
      state:string
  };
  product: {
      id: number,
      model: string;
  };
  aTM: {
      id: string,
      mfrAndModel: string;
  };
  address: {
      name: string;
      name2: string;
      city: string;
      state: string;
      addressLine1: string;
  };
  rep: {
    id: number;
    name: string;
    firstName: string;
    lastName: string;
};
distributor:{
  id: number;
  name: string;
}
}

interface atmTickets{
  id: string,
  atmModel: string
}
interface productsTickets{
  id: number,
  model: string
}

interface repTickets{
  id: number;
  fullName: string;
}

interface distributerTickets{
  id: number;
  name: string;
}
@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss']
})
export class NewJobComponent implements OnInit {
@Output() getAllJobs: EventEmitter<any> = new EventEmitter(); 
  isNewCustomer: boolean = false;
  atmModels: atmTickets[] = [];
  productModels: productsTickets[] = [];
  jobsData: JobsTickets[] = [ ];
  distributorData: distributerTickets[] = [];
  nextSection: boolean = false;
  contactSection: boolean = false;
  additionalSection: boolean = false;
  newJobProduct: boolean = false;
  equipRep: boolean = false;
  step: number = 0;
  newJobForm: FormGroup;
  states: any;
  customerList: any[]= [];
  customerCopyList: any[]=[];
  productsList: any[]=[];
  productsCopyList: any[]=[];
  atmList: any[]=[];
  atmCopyList: any[]=[];
  repsCopyList: any[]=[];
  repsList: any[]=[];
  payload = {}
  userList: any[]=[];
  userCopyList: any[]=[];
  distributorsList: any[]=[];
  distributorsCopyList: any[]=[];
  contactList: any;

  constructor(private dataService: DataService, private modalService: NgbModal,private router : ActivatedRoute, private formBuilder: FormBuilder, private route: Router) { }
  ngOnInit(): void {
    this.initializenewJobForm();
    this.getStates();
    this.getCustomerList();
    this.getProductsList();
    this.getATMList();
    this.getRepsData();
    this.getUserList();
    this.getDistributorsList();
  }

  //************************* New Job Form Initialization *************************/ 
  initializenewJobForm() {
    this.newJobForm = this.formBuilder.group({
      customerId: ['', Validators.required],
      state: ['', Validators.required],
      customerName: ['', Validators.required],
      city: ['', Validators.required],
      contactFName: [''],
      contactLName: [''],
      addContactFName: [''],
      addContactLName: [''],
      productId: ['', Validators.required],
      atmId: ['', Validators.required],
      isMJob: [false],
      coordinatorId: [''],
      projManagerId: [''],
      repId: [''],
      repFName: [''],
      repLName: [''],
      contactId: [{value: '', disabled: true} ],
      addlContactId: [{value: '', disabled: true}],
    });
  }

  //*----------------------************************ API Calls ***********************--------------------------*/ 

  //************************* Get CustomerList API *************************/ 

  getCustomerList() {
    let body = {
      "start": environment.Start,
      "size": 25000,
      "orderBy": "t1.name",
      "where": "t1.type = '0' or t1.type is null"
    }
      this.dataService.getCustomers(body).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
            result.items.forEach(element => {
              this.customerList = result.items.map(element => ({
                id: element.id,
                name: `${element.name} - ${element.city}, ${element.state}`,
                searchString: `${element.name} - ${element.city}, ${element.state}`,
                customerName: element.name
              }));           
             });
            this.customerCopyList = [...this.customerList].splice(0, 50);
          }
        },
        error: (error) => { }
      })
    }
    
    customerFilterList(event: any) {
      const inputValue = event.target?.value || '';
      if (event.keyCode === 38 || event.keyCode === 40) {
        return;
      }
      if (inputValue.length <= 1) {
          this.newJobForm.controls['customerId'].setValue(null);
        }
      
      const searchValue = inputValue.toLowerCase().trim();
      
      this.customerCopyList = this.customerList
        .filter(customer => {
          if (searchValue.includes('-')) {
            const [bankPart, locationPart] = searchValue.split('-').map(part => part.trim());
            const [customerBankPart, customerLocationPart] = customer.searchString.toLowerCase().split('-').map(part => part.trim());
            if (!customerBankPart.startsWith(bankPart)) {
              return false;
            }
            if (locationPart && locationPart.length > 0) {
              return customerLocationPart.startsWith(locationPart);
            }
            return true;
          } else {
            const customerBankPart = customer.searchString.toLowerCase().split('-')[0].trim();
            return customerBankPart.startsWith(searchValue);
          }
        })
        .slice(0, 50);
    }
    
  //************************* Get ProductList API *************************/ 

    getProductsList(){    
      this.dataService.GetDateProducts({"dateSpecId": -1}).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
            result?.forEach(element => {
              this.productsList.push({id:element.productId,  model:element.model})      
            });
            this.productsCopyList = [...this.productsList].splice(0, 50);
          }
        },
        error: (error) => { }
      })
    }
    productFilterList(event){
      const inputValue = event.target?.value || '';  
      if (event.keyCode != 38 && event.keyCode != 40) {
        if (inputValue?.length == 1 || inputValue?.length == 0) this.newJobForm.get('productId').setValue(null)
      this.productsCopyList = this.productsList
      .filter(customer => customer.model?.toLowerCase().includes(event.target.value?.toLowerCase()))
      .slice(0, 50);
      }
    }
    
  //************************* Get ATMList API *************************/ 

    getATMList(){    
      let body = {
        "start": environment.Start,
        "size": 10000,
        "orderBy": "mfrAndModel",
        "where": "includeInJobs is true"
    }
      this.dataService.getATMList(body).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
            result.items.forEach(element => {
              this.atmList.push({id:element.id, mfrAndModel:element.mfrAndModel})      
            });
            this.atmCopyList = [...this.atmList].splice(0, 50);        
          }
          },
        error: (error) => { }
      })
    }
    atmFilterList(event){
      const inputValue = event.target?.value || '';  
      if (event.keyCode != 38 && event.keyCode != 40) {
        if (inputValue?.length == 1 || inputValue?.length == 0) this.newJobForm.get('atmId').setValue(null)
      this.atmCopyList = this.atmList
      .filter(customer => customer.mfrAndModel?.toLowerCase().includes(event.target.value?.toLowerCase()))
      .slice(0, 50);
      }
    }
    
  //************************* Get RepList API *************************/ 

    getRepsData() {    
      let body = {
        "start": environment.Start,
        "size": 25000,
        "orderBy": "t1.name"
    }
      this.dataService.getReps(body).subscribe({
        next: (result) => {
          if (result.status == 'failed') {
            // Handle failed request
          } else {
            result.items.forEach(element => {
              this.repsList.push({id:element.id, name:element.name})      
            }); 
            this.repsCopyList = [...this.repsList].splice(0, 50);
          }
        },
        error: (error) => {
          // Handle error
        }
      });
    }
    repFilterList(event){
      const inputValue = event.target?.value || '';  
      if (event.keyCode != 38 && event.keyCode != 40) {
        if (inputValue?.length == 1 || inputValue?.length == 0) this.newJobForm.get('repId').setValue(null)
      this.repsCopyList = this.repsList
      .filter(customer => customer.name?.toLowerCase().includes(event.target.value?.toLowerCase()))
      .slice(0, 50);
      }
    }
    
  //************************* Get UserList API *************************/ 

    getUserList(){    
      let body = {
        "className": "User",
        "size": 10000,
        "start": environment.Start,
        "orderBy": "userId",
        "where": "role != 'viewer' and systemUser = 0"
    } 
      this.dataService.vault(body).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
            result.items.forEach(element => {
              this.userList.push({id:element.id, name:element?.firstName + ' ' + element?.lastName})     
            }); 
            this.userCopyList = [...this.userList].splice(0, 50);        
          }
        },
        error: (error) => { }
      })
    }
    userFilterList(event, check){ 
      const inputValue = event.target?.value || '';  
    if (event.keyCode != 38 && event.keyCode != 40) {
      check == '1' ? this.newJobForm.get('coordinatorId').setValue(null) : this.newJobForm.get('projManagerId').setValue(null);  
      this.userCopyList = this.userList
      .filter(customer => customer.name?.toLowerCase().includes(event.target.value?.toLowerCase()))
      .slice(0, 50);
      }
    }
    
  //************************* Get DistributorsList API *************************/ 

    getDistributorsList(){    
      let body = {
        "start": environment.Start,
        "size": 25000,
        "orderBy": "t1.name",
        "where": "type = '1'"
    }
      this.dataService.getDistributorsList(body).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
         result.items.forEach(element => {
          this.distributorsList.push({id:element.id,  name:element.name})     
        }); 
        this.distributorsCopyList = [...this.distributorsList].splice(0, 50);  
          }
        },
        error: (error) => { }
      })
    }
    distributorFilterList(event){
      const inputValue = event.target?.value || '';  
      if (event.keyCode != 38 && event.keyCode != 40) {
        if (inputValue?.length == 1 || inputValue?.length == 0) this.newJobForm.get('distributorId').setValue(null)
      this.distributorsCopyList = this.distributorsList
      .filter(customer => customer.name?.toLowerCase().includes(event.target.value?.toLowerCase()))
      .slice(0, 50);
      }
    }
    
  //************************* Get StatesList API *************************/ 

      getStates(){    
        this.dataService.getStates().subscribe({
          next: (result) => {
            if (result.status=='failed') {
            } else {
              this.states=result;
            }
          },
          error: (error) => { }
        })
      }

  //************************* Get ContactsList API *************************/
      getContacts(){ 
        let body = {
          "start":environment.Start,
          "size":25000,
          "where":"customerId=" + this.newJobForm?.value?.customerId,
          "orderBy":"t1.name"
          }   
        this.dataService.GetContacts(body).subscribe({
          next: (result) => {
            if (result.status=='failed') {
            } else {
              this.contactList=result?.items;
              if (this.contactList?.length <= 0) {
                this.newJobForm.get('contactId').disable();
                this.newJobForm.get('addlContactId').disable();
              } else {
                this.newJobForm.get('contactId').enable();
                this.newJobForm.get('addlContactId').enable();
              }        }
          },
          error: (error) => { }
        })
      }
    
  //************************* Creating NewJob API *************************/ 

  onDropdownClose(check) {
    if (check == 'customer') {
      this.customerCopyList = [...this.customerList].splice(0, 50);
    }
    if (check == 'product') {
      this.productsCopyList = [...this.productsList].splice(0, 50);
    }
    if (check == 'atm') {
      this.atmCopyList = [...this.atmList].splice(0, 50);
    }
    if (check == 'distributor') {
      this.distributorsCopyList = [...this.distributorsList].splice(0, 50);
    }
    if (check == 'rep') {
      this.repsCopyList = [...this.repsList].splice(0, 50);
    }
    if (check == 'coordinator') {
      this.userCopyList = [...this.userList].splice(0, 50);
    }
  }
    saveJob(){
      this.payloadCreation();  
      this.dataService.SaveJob(this.payload).subscribe({
        next: (result) => {
          if (result.status=='failed') {
          } else {
              this.getAllJobs.emit(true);
              this.dismiss();
              this.route.navigate(['/jobs/job-details'], { queryParams:{id : result?.id} });
            }
        },
        error: (error) => { }
      })
    }
    
  //************************* Creating Payload *************************/ 

    payloadCreation() {
      this.payload = {}
    
      this.payload = {
        "status1": "prospect",
        "isNewCustomer" : this.isNewCustomer,
        "isNewAddlContact": this.contactSection,
        "isNewContact": this.additionalSection,
        "isNewEquipRep": this.equipRep,
        "mJob": this.newJobForm.get('isMJob').value,
        "productId": this.newJobForm.get('productId').value,
        "atmId": this.newJobForm.get('atmId').value,
        "coordinatorId": this.newJobForm.get('coordinatorId').value,
        "projectManagerId": this.newJobForm.get('projManagerId').value,
      }
    
      if (!this.isNewCustomer) { 
        this.payload['customerId'] = this.newJobForm.get('customerId').value;
      } else if (this.isNewCustomer) {
        let tempObject ={
          "name": this.newJobForm.get('customerName').value,
          "city": this.newJobForm.get('city').value,
          "state": this.newJobForm.get('state').value
        }
        this.payload['customer'] = tempObject
        this.payload['customerId'] = "";
      }
    
      if (this.contactSection) {
        let tempObject ={
          "firstName": this.newJobForm.get('contactFName').value,
          "lastName": this.newJobForm.get('contactLName').value,
          "fullName": this.newJobForm.get('contactFName').value + ' ' + this.newJobForm.get('contactLName').value,
          "name": this.newJobForm.get('contactFName').value + ' ' + this.newJobForm.get('contactLName').value
        }
        this.payload['contact'] = tempObject;
        this.payload["contactId"] = "";
      } else if (!this.contactSection) {
        this.payload["contactId"] = this.newJobForm.get('contactId').value;
      }
    
      if (this.additionalSection) {
        let tempObject = {
          "firstName": this.newJobForm.get('addContactFName').value,
          "lastName": this.newJobForm.get('addContactLName').value,
          "fullName": this.newJobForm.get('addContactFName').value + ' ' + this.newJobForm.get('addContactLName').value,
          "name": this.newJobForm.get('addContactFName').value + ' ' + this.newJobForm.get('addContactLName').value
        }
        this.payload['addlContact'] = tempObject;
        this.payload["addlContactId"] = "";
      } else if (!this.additionalSection) {
        this.payload["addlContactId"] = this.newJobForm.get('addlContactId').value;
      }
    
      if (this.equipRep) {
        let tempObject = {
          "firstName": this.newJobForm.get('repFName').value,
          "lastName": this.newJobForm.get('repLName').value,
          "fullName": this.newJobForm.get('repFName').value + ' ' + this.newJobForm.get('repLName').value,
          "name": this.newJobForm.get('repFName').value + ' ' + this.newJobForm.get('repLName').value
        }
        this.payload['rep'] = tempObject;
        this.payload["repId"] = "";
      } else if (!this.equipRep) {
        this.payload["repId"] = this.newJobForm.get('repId').value;
      }
    }
    
  //*---------************************ Toggle Functionalities ************************-----------*/ 

    toggleCustomerSection(){
      this.isNewCustomer = !this.isNewCustomer;
    }
  
    toggleEquiptmentRep(){
      this.equipRep = !this.equipRep;
    }
  
    toggleContactSection(){
      this.contactSection = !this.contactSection;
    }
  
    toggleAdditionalSection(){
      this.additionalSection = !this.additionalSection;
    }
  
  //************************* Itrate Through Pages *************************/ 

  nextPage() {
      if (this.step === 0) {
        if (this.isNewCustomer) {
          if (!this.newJobForm.get('state').valid || !this.newJobForm.get('customerName').valid || !this.newJobForm.get('city').valid) {
            this.newJobForm.get('state').markAsTouched();
            this.newJobForm.get('customerName').markAsTouched();
            this.newJobForm.get('city').markAsTouched();
            return;
          }
        } else {
          if (!this.newJobForm.get('customerId').valid) {
            this.newJobForm.get('customerId').markAsTouched();
            return;
          }
        }
        this.step = 1;
        this.newJobForm.get('customerId').markAsUntouched();
        this.newJobForm.get('state').markAsUntouched();
        this.newJobForm.get('customerName').markAsUntouched();
        this.newJobForm.get('city').markAsUntouched();
      } 
      else if (this.step === 1) {
          this.step = 2;
      } 
      else if( this.step === 2) {
        if (!this.newJobForm.get('productId').valid || !this.newJobForm.get('atmId').valid) {
          this.newJobForm.get('productId').markAsTouched();
          this.newJobForm.get('atmId').markAsTouched();
          return;
        }
        this.step = 3;
      }
  }

  previousPage() {
    if (this.step === 1) {
        this.step = 0;
    } 
    else if (this.step === 2) {
        this.step = 1;
    }
    else if (this.step === 3) {
      this.step = 2;
  }
  }

  //************************* Dismiss functionality *************************/ 

  dismiss(){
    this.resetForm();
    this.newJobForm.reset();
    this.modalService.dismissAll()
  }
  resetForm() {
    this.isNewCustomer = false;
    this.contactSection = false;
    this.additionalSection = false;
    this.equipRep = false; 
    this.step = 0;
  }



  contactReset(event, check){
    const inputValue = event.target?.value || '';  
    if (inputValue?.length == 1 || inputValue?.length == 0) 
     check == '1' ? this.newJobForm.get('contactId').setValue(null) : this.newJobForm.get('addlContactId').setValue(null);  
  }


  searchStateReset(event){  
    const inputValue = event.target?.value || '';  
    if (inputValue?.length == 1 || inputValue?.length == 0) 
      this.newJobForm.get('state').setValue(null)   
  }


}
