import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { INavbarData } from './helper';
import { navbarData } from './nav-data';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('faadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('350ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('350ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('rotate', [
      transition(':enter', [
        animate(
          '1000ms',
          keyframes([
            style({ transform: 'rotate(0deg)', offset: '0' }),
            style({ transform: 'rotate(2turn)', offset: '1' }),
          ])
        ),
      ]),
    ]),
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible <=> hidden', [
        style({ overflow: 'hidden' }),
        animate('{{transitionParams}}'),
      ]),
      transition('void => *', animate(0)),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() collapsed: any;
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  screenWidth: number = 0;
  navData = [];
  multiple: boolean = false;
  expandedList: any = [];
  displayCollapseAll: boolean = false;
  subscribeSidebarbehaviour: Subscription;
  subscribeSidebarItemsbehaviour: Subscription;
  miniSidebarCollapse: boolean = false;
  targetObjectPath: string = '';
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.drawSideBar();
    this.subscribeSidebarItemsbehaviour =
      this.dataService.sideBarItem.subscribe((response) => {
        if (response != '') {
          this.targetObjectPath = response          
          this.updateNavigationState(this.navData, response);
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.collapsed ? this.updateNavigationState(this.navData, this.targetObjectPath) : '';
  }

  drawSideBar() {
    let drawSideBarItems: any = [];
    drawSideBarItems = JSON.parse(JSON.stringify(navbarData));

    const oUserDetails = this.sessionService.SessionService_GetUserDetails();
    const userRole = oUserDetails.role;

    this.navData = this.filterByRole(drawSideBarItems, userRole);

    let oSelectedSideBarItem = JSON.parse(
      localStorage.getItem('SideBarSelectedItem')
    );
    if (oSelectedSideBarItem) {
      this.updateNavigationState(this.navData, oSelectedSideBarItem.path);
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }

  // Function to filter items based on role
  filterByRole(navItems: any[], userRole: string): any[] {
    const roleMap = { admin: 'A', manager: 'M', coordinator: 'C' };
    const abbreviatedRole = roleMap[userRole] || '';

    return navItems.filter((item) => {
      if (
        item.access.includes('all') ||
        item.access.includes(abbreviatedRole)
      ) {
        item.items = item.items;
        // item.items = item.items ? this.filterByRole(item.items, userRole) : [];
        return true;
      }
      return false;
    });
  }

  updateNavigationState(navItems, targetObjectPath) {
    this.makeActiveFalse(this.navData);
    for (const item of navItems) {
      if (item.path === targetObjectPath) {
        item.isActive = true;
        item.isOpen = true;
        if (!item.items)
          localStorage.setItem('SideBarSelectedItem', JSON.stringify(item));
        return true;
      } else if (item.items) {
        if (this.updateNavigationState(item.items, targetObjectPath)) {
          this.miniSidebarCollapse || this.collapsed? item.isOpen = true : '';
          this.displayCollapseAll = true;
          return true;
        }
      }
    }
    return false;
  }
  makeActiveFalse(items) {
    items.forEach((element) => {
      element.isActive = false;
      element.isOpen = false;
      if (element.items) {
        this.makeActiveFalse(element.items);
      }
    });
  }
  onCollapsedNavigating(subItem) {
    this.router.navigateByUrl(subItem.routerLink);
  }

  async toggleSubItems(item: INavbarData) {
    if (item.items) {
      this.navData.forEach((elem) => {
        if (item.label != elem.label) elem.isOpen = false;
      });
      setTimeout(() => {
        item.isOpen = !item.isOpen;
        this.miniSidebarCollapse = true;
      }, 50);
    }
    if (item.routerLink != '') {
      await this.recursiveFunction(this.navData);
      item.isActive = !item.isActive;
      if (!item.items)
        localStorage.setItem('SideBarSelectedItem', JSON.stringify(item));
      localStorage.removeItem('lFilterList');
      localStorage.removeItem('currentPage');
      localStorage.removeItem('pageSize');
    }
    this.displayCollapseAll = this.checkCollapseAll(this.navData, false);
  }

  checkCollapseAll(oIncomingArray, oIncomingBoolean) {
    oIncomingArray.forEach((item) => {
      if (item.isOpen) oIncomingBoolean = true;
      if (item.items) {
        if (item.isOpen) oIncomingBoolean = true;
        this.checkCollapseAll(item.items, oIncomingBoolean);
      }
    });
    return oIncomingBoolean;
  }

  collapseSideBar(oIncomingArray) {
    oIncomingArray.forEach((item) => {
      item.isOpen = false;
      if (item.items) {
        item.isOpen = false;
        this.collapseSideBar(item.items);
      }
    });
    this.displayCollapseAll = false;
  }

  recursiveFunction(oIncomingArray) {
    oIncomingArray.forEach((item) => {
      item.isActive = false;
      if (item.items) {
        item.isActive = false;
        this.recursiveFunction(item.items);
      }
    });
  }

  // handleClick(item: INavbarData)
  // {
  //   if(!this.multiple) {
  //     for(let modelItem of this.navData){

  //       if(item !== modelItem && modelItem.expanded){
  //         modelItem.expanded = false;
  //         this.expandedList.push(item)
  //       }
  //     }
  //   }
  //   item.expanded = !item.expanded;
  // }
  outSideDropDownsClick(comingItem) {
    if (comingItem.isOpen) {
      comingItem.isOpen = false;
    }
  }
  ngOnDestroy(): void {
    if (this.subscribeSidebarbehaviour)
      this.subscribeSidebarbehaviour.unsubscribe();
    if (this.subscribeSidebarItemsbehaviour)
      this.subscribeSidebarItemsbehaviour.unsubscribe();
  }
  collapseSublevelMenus(): void {
    this.navData.forEach((item) => {
      this.collapseSublevelMenu(item);
    });
  }

  collapseSublevelMenu(item: any): void {
    if (item.items) {
      item.isOpen = false;
      item.items.forEach((subItem) => {
        this.collapseSublevelMenu(subItem);
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1025) {
      this.collapseSublevelMenus(); // Collapse sublevel menus when screen width is less than or equal to 992px
    }
  }
}
