<div class="sidenav shadow" [ngClass]="collapsed ? 'sidenav-collapsed' : 'sidenav-open'">
  <ul class="sidenav-nav p-0">
    <ng-container *ngFor="let item of navData">
      <ng-container *ngTemplateOutlet="recursiveItem; context: { $implicit: item }"></ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #recursiveItem let-item>
  <li class="sidenav-nav-item" *ngIf="collapsed" [ngClass]="!collapsed ? 'px-1' : 'px-3'"
    [class.active]="item.isActive">
    <a href="javascript:void(0)" class="sidenav-nav-link"
      [routerLink]="item.routerLink !== '' ? [item.routerLink] : null" routerLinkActive="active"
      (click)="toggleSubItems(item)">
      <i class="sidenav-link-icon fa-solid {{item.icon}}"></i>

      <span class="sidenav-link-text">{{ item.label }}</span>
      <i *ngIf="item.items" class="menu-collapse-icon fa-solid"
        [ngClass]="!item.isOpen ? 'fa-angle-left' : 'fa-angle-down'">
      </i>
    </a>
    <ul *ngIf="item.isOpen && item.items" class="sublevel-nav pl-1" [@submenu]="item.isOpen ? {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'*'}} :
      {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'0'}}">
      <ng-container *ngFor="let subitem of item.items">
        <ng-container *ngTemplateOutlet="recursiveItem; context: { $implicit: subitem }"></ng-container>
      </ng-container>
    </ul>
  </li>
  <li class="sidenav-nav-item" *ngIf="!collapsed" [ngClass]="!collapsed ? 'px-1' : 'px-3'"
    [class.active]="item.isActive">
    <a href="javascript:void(0)" class="sidenav-nav-link"
      [routerLink]="item.routerLink !== '' ? [item.routerLink] : null" routerLinkActive="active"
      (click)="toggleSubItems(item)" [routerLinkActiveOptions]="{exact:false}">
      <i class="sidenav-link-icon fa-solid {{item.icon}}"></i>
      <div class="d-inline-block me-3">
        <div appOutsideClick (appOutsideClick)="outSideDropDownsClick(item)" class="profile-icon">
          <i *ngIf="item.items" class="menu-collapse-icon fa-solid"
            [ngClass]="{'fa-angle-left': !item.isOpen, 'fa-angle-down': item.isOpen}"></i>
          <ul *ngIf="item.isOpen && item.items && miniSidebarCollapse"
            class="sublevel-nav custom-dropdown border cursor-pointer pl-0 px-2 rounded-1 shadow">
            <li (click)="onCollapsedNavigating(subitem)" *ngFor="let subitem of item.items"
              class="border-bottom py-2 d-flex flex-column gap-3">
              <span class="px-3 dropdown-label">{{subitem.label}}</span>
            </li>
          </ul>
        </div>
      </div>
    </a>
  </li>
</ng-template>