const env = window["env"] || {};

export const environment = {
  production: env["production"] || false,
  BaseURL: env["BaseURL"] || 'https://api.tms-staging-420421.trilloapps.com',
  AccessToken : env["AccessToken"] || 'lsAccessToken',
  Server: env["Server"] || 'staging',
  SessionExpiryTime: env["SessionExpiryTime"] || 35000,
  Start: env["Start"] || 1,
  Size: env["Size"] || 100
};
