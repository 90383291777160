import { Component, Renderer2 } from '@angular/core';
import { BusyLoaderService } from './services/busyloader.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { SessionService } from './services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  sessionExpTime: number = environment?.SessionExpiryTime
  lastPing?: Date = undefined;

  constructor(
    private loaderService: BusyLoaderService,
    private renderer: Renderer2,
    private oCheckIdleState: Idle,
    private keepalive: Keepalive,
    private oRouter: Router,
    private sessionService: SessionService,
  ) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    oCheckIdleState.setIdle(5);
    // oCheckIdleState.setTimeout(this.sessionExpTime);
    
    oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        this.renderer.addClass(document.body, 'loading');
      } else {
        this.renderer.removeClass(document.body, 'loading');
      }
    });
    
    oCheckIdleState.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log("On idle end Idle state : ==> ", this.idleState);
    });
    
    oCheckIdleState.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log("On Time out Idle state : ==> ", this.idleState);
      localStorage.clear();
      this.AppComponent_ResetTimer();
      this.oRouter.navigateByUrl('/authentication/login');
    });
    
    oCheckIdleState.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log("On idle start Idle state : ==> ", this.idleState);
    });
    
    oCheckIdleState.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log( this.idleState);
    });
    
    keepalive.interval(5);
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      this.sessionService.cSessionService_setSessionExpiry(this.sessionExpTime*1000);
    });
    
    this.AppComponent_ResetTimer();
    
    // Subscribe to idleControl$ to reset the idle timer
    this.sessionService.sessionExpiryTime$.subscribe((idleTime) => {
      this.sessionExpTime = + idleTime * 3600 || environment?.SessionExpiryTime;  
      console.log("Session Expiry Time is ---->", this.sessionExpTime);
      oCheckIdleState.setTimeout(this.sessionExpTime);
      this.sessionService.cSessionService_setSessionExpiry(this.sessionExpTime*1000);
      this.AppComponent_ResetTimer();
    });
  }
  
  AppComponent_ResetTimer() {
      this.AppComponent_CheckSessionExpiry();
      this.oCheckIdleState.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
      // console.log("AppComponent_ResetTimer");
    
  }

  AppComponent_CheckSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = this.sessionService.cSessionService_getSessionExpiry();
    const nCurrentTime = oDate.getTime();

    if (nSessionExpiryTime !== null) {
      // console.log("Session_CheckSessionExpiry: Session Expiry Time {%s}", nSessionExpiryTime);
      // console.log("Session_CheckSessionExpiry: Current Time {%s}", nCurrentTime);
      if (nCurrentTime > nSessionExpiryTime) {
        localStorage.removeItem("exp-ls-" + "SessionExpiryTime");
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/authentication/login');
      }
    }
  }
}
