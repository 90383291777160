import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { SublevelMenuComponent } from './sidebar/sublevel-menu/sublevel-menu.component';
import { AppOutsideClickDirective } from './directives/app-outside-click.directive';
import { NewJobComponent } from './new-job/new-job.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AlertsComponent,
    LoaderComponent,
    SidebarComponent,
    HeaderComponent,
    SublevelMenuComponent,
    AppOutsideClickDirective,
    NewJobComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot()

  ],
  exports: [AlertsComponent,LoaderComponent,SidebarComponent,HeaderComponent, AppOutsideClickDirective, NewJobComponent, BsDatepickerModule]
})
export class SharedModule { }



