<ul *ngIf="collapsed && data.items && data.items.length > 0 && expanded" class="sublevel-nav"
    [@submenu]="expanded ? {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'*'}}:
   {value:'visible', params: {transitionParams:'400ms cubic-bezier(0.86,0,0.07,1)',height:'0'}}"
    >
     <li *ngFor="let item of data.items" class="sublevel-nav-item" routerLinkActive="active-sublevel">
       
       <a href="javascript:void(0)" class="sublevel-nav-link"  *ngIf="item.items && item.items.length>0 && item.expanded"  (click)=handleClick(item)>
         <!-- <i class="sublevel-link-icon {{item.icon}} sidebar-icons"></i> -->
         <span class="sublevel-link-text" *ngIf="collapsed">{{item.label}}</span>
         <i *ngIf="item.items && collapsed" class="menu-collapse-icon fa-solid" 
         [ngClass]="!item.expanded ? 'fa-angle-right':'fa-angle-down'"></i>
       </a>
       <a href="javascript:void(0)" class="sublevel-nav-link" *ngIf="!item.items || (item.items && item.items.length === 0)"
       [routerLink]="[item.routerLink]"
       
       >
       <!-- <i class=" {{item.icon}} sidebar-icons"></i> -->
       <!-- <i class="sublevel-link-icon fa-solid fa-circle"></i> -->
       <i class=" sublevel-link-icon fa-solid fa-circle"></i>
       <span class="sublevel-link-text" 
        *ngIf="collapsed">{{item.label}}</span>
     </a>
     <div *ngIf="item.items && item.items.length > 0">
       <app-sublevel-menu
         [collapsed]="collapsed"
         [multiple]="multiple"
         [expanded]="item.expanded"
       ></app-sublevel-menu>
     </div>
     </li>
    </ul>